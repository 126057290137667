/*
 * @Description: 
 * @Author: Rabbiter
 * @Date: 2023-03-26 15:27:05
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseApi: "http://ribendingding.top/api/",
	imgApiURL: "http://ribendingding.top/api/",
	// baseApi: "http://127.0.0.1:9191",
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
